.chat {
  margin-top: var(--spacing-5);
  height: calc(100% - var(--spacing-5));
}

.chat__container {
  height: 100%;
  padding: 24px;
  color: var(--text);
  background-color: white;
}

.chat__content {
  position: relative;
  display: flex;
  gap: 24px;
  height: 100%;
}

.chat__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 14px;
  width: 100%;
}

.chat__thread-toggle {
  flex-shrink: 0;
}

.chat__threads {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  flex: 0 0 240px;
  padding: 16px;
  background-color: var(--surface100);
  border-radius: 8px;
  overflow-x: hidden;
  z-index: 1;
}

.chat__thread-container {
  position: relative;
}

.chat__thread {
  width: 100%;
  padding: 12px 12px 12px 0;
  border-bottom: 1px solid var(--border300);
  text-align: left;
  opacity: 0.8;
}

.chat__thread.active {
  border-color: var(--surface400);
  font-weight: 500;
  opacity: 1;
}

.chat__threads-title {
  opacity: 0.8;
  margin-bottom: 8px;
}

.chat__new {
  justify-content: center;
  margin-top: auto;
}

.chat__thread-delete {
  position: absolute;
  top: 50%;
  right: 0;
  color: var(--error-text);
  padding: 5px;
  border-radius: 50%;
  transform: translate(50%, -50%);
  opacity: 0.5;
  z-index: 1;
}

.chat__thread-delete:hover,
.chat__thread-delete:focus-visible {
  opacity: 1;
}

.chat__chat {
  position: relative;
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  transition: var(--transition);
}

.chat__content.open .chat__chat {
  flex: 1 1 100%;
}

.chat__form {
  position: relative;
  display: flex;
  gap: 24px;
  width: 100%;
}

.chat__input-container {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 8px;
  background-color: var(--surface100);
  border-radius: 8px;
}

.chat__input {
  padding: 13px 12px;
}

.chat__input .input__field {
  background-color: transparent;
  border: none;
  border: 2px solid transparent;
  border-radius: 8px;
  padding: 0;
  font-size: 2rem;
  line-height: 1.5;
  letter-spacing: -0.05em;
  max-height: calc(6rem + 26px);
  resize: none;
  scrollbar-color: var(--surface300) transparent;
}

.chat__input .input__field:focus-visible {
  border-color: var(--primary);
}

.chat__messages-container {
  flex-grow: 1;
  position: relative;
  width: 100%;
}

.chat__messages {
  display: flex;
  flex-direction: column-reverse;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  padding: 0 10px 24px;
  height: 100%;
  overflow-y: auto;
}

.chat__message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 60px 0 0;
}

.chat__message-container--user {
  align-self: flex-end;
  align-items: flex-end;
  margin: 0 0 0 60px;
}

.chat__message {
  padding: 8px 16px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid var(--surface200);
}

.chat__message-container--user .chat__message {
  background-color: var(--surface100);
  border: none;
}

.chat__message-container:first-of-type {
  margin-bottom: auto;
}

.chat__message-date {
  opacity: 0.5;
  font-size: 1.4rem;
}

.chat__btn .loader {
  display: block;
  font-size: 0.2em;
  margin: 0.8rem;
}

.chat.loading .chat__messages {
  opacity: 0.5;
}

.chat__thread-back {
  align-self: flex-start;
  margin-bottom: 24px;
}

.chat .markdown p:not(:last-child) {
  margin: 0 0 4px;
}

.chat pre {
  white-space: pre-wrap;
}

@media only screen and (max-width: 767px) {
  .chat__chat {
    flex: 0 0 100%;
  }

  .chat__messages {
    padding: 0 0 16px;
  }

  .chat__threads {
    flex: 0 0 100%;
    background-color: white;
    padding: 0;
    border-radius: 0;
    border: 0;
  }

  .chat__thread-container {
    width: calc(100% - 8px);
  }

  .chat__content.open .chat__chat {
    margin-left: calc(-100% - 24px);
  }
}
