.security-privacy__container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 160px;
}

.security-privacy__forms {
  flex: 0 0 100%;
  width: 100%;
}

@media only screen and (max-width: 1279px) {
  .security-privacy__container {
    flex-direction: column;
  }

  .security-privacy__forms {
    margin-top: 24px;
  }

  .security-privacy__container .scrollspy {
    order: -1;
  }
}
