.input {
  width: 100%;
  font-size: 2rem;
}

.disabled {
  background-color: #f2f3f6 !important;
  border-color: #d5dde9;
}

.input__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 8px;
}

.input__link {
  text-decoration: underline;
}

.input__label {
  margin-right: auto;
  display: block;
}

.input__wrapper {
  display: flex;
  position: relative;
}

.input__field {
  width: 100%;
  padding: 14px 11px;
  font-size: 1.8rem;
  line-height: 1.5;
  background-color: white;
  color: var(--text);
  font-family: 'Albert', Sans-Serif;
  border: 1px solid var(--primary100);
  border-radius: 2px;
}

.input.with-icon .input__field,
.input__field--password,
.input__field--select {
  padding-right: 40px;
}

.input__field:focus-visible {
  border-color: var(--primary);
}

.input__field--select:focus-visible {
  padding-right: 40px;
}

.input__field--select option:first-of-type {
  color: rgba(35, 59, 75, 0.5);
}

.input__field::placeholder {
  color: var(--text);
  opacity: 0.5;
}

.input.error .input__field {
  border-color: var(--error200);
}

.input__error {
  margin-top: 4px;
  color: var(--error-text);
}

.input__btn {
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  padding: 5px;
  border: none;
  color: var(--primary);
}

.input__btn svg {
  display: block;
}

.input__field--select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: ellipsis;
  cursor: pointer;
}

.input__field--select::-ms-expand {
  display: none;
}

.input__field--select:invalid {
  color: rgba(35, 59, 75, 0.5);
}

.input__field--select option {
  color: var(--text);
}

.input__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: var(--primary);
  user-select: none;
  pointer-events: none;
}

.input--checkbox {
  position: relative;
  padding-left: 40px;
}

.input__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.input__checkbox-label {
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input__checkbox-label a {
  text-decoration: underline;
}

.input__checkbox-label a:hover {
  color: var(--primary);
}

.input__checkbox:focus-visible ~ .input__checkmark {
  outline: 2px solid var(--text);
}

.input__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  border-radius: 2px;
  background-color: white;
  border: 1px solid var(--primary100);
  transition: var(--transition);
}

.input__checkmark.checked {
  background: var(--primary);
  border-color: var(--primary);
}

.input__checkmark-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  color: white;
}

/* Google place */

.pac-item {
  padding: 4px 8px;
  font-family: 'Albert', Sans-Serif;
  font-size: 1.8rem;
  font-weight: 400;
  cursor: pointer;
}

.pac-item-query,
.pac-matched {
  font-size: 2rem;
  font-weight: 500;
}

.pac-item-query {
  margin-right: 5px;
}
