body,
html {
  overflow-x: hidden;
  overflow-x: clip;
}

body {
  position: relative;
  transition: background-color var(--transition);
}

.app {
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
}

.wrap {
  width: 100%;
  max-width: 1568px;
  padding: 0 var(--spacing-10);
  margin: 0 auto;
}

.wrap--xl {
  max-width: 1600px;
  padding: 0;
}

.wrap--sm {
  max-width: 880px;
}

.wrap--xs {
  max-width: 800px;
}

.section {
  margin: var(--spacing-20) 0;
}

.section-bg {
  padding: var(--spacing-20) 0;
}

.section-bg--grey {
  background-color: var(--surface100);
}

.section-bg--primary {
  background-color: var(--primary);
}

.section-bg--secondary {
  background-color: var(--secondary);
}

.section-bg--white {
  background-color: white;
}

.section-bg--dark {
  background-color: var(--text);
}

.section-bg--primary,
.section-bg--dark,
.section-bg--secondary {
  color: white;
}

.section-bg--grey + .section-bg--grey,
.section-bg--primary + .section-bg--primary,
.section-bg--white + .section-bg--white,
.section-bg--dark + .section-bg--dark,
.section-bg--secondary + .section-bg--secondary {
  padding-top: 0;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon-large {
  width: 24px;
  height: 24px;
}

.icon-xl {
  width: 36px;
  height: 36px;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-minus-90 {
  transform: rotate(-90deg);
}

.ml-auto {
  margin-left: auto;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cover-img,
.contain-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cover-img {
  object-fit: cover;
}

.contain-img {
  object-fit: contain;
}

.video {
  position: relative;
}

.video__container {
  display: block;
  position: relative;
  padding-top: 56.25%;
}

.video .video-js {
  position: absolute;
  background-color: transparent;
}

.video .vjs-poster img {
  object-fit: cover;
}

.fade-in {
  opacity: 0;
  animation: fade-in 1s 1s forwards;
}

body div#hs-eu-cookie-confirmation {
  position: fixed;
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.l-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.l-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.l-6 {
  -webkit-line-clamp: 6;
  line-clamp: 6;
}

.image-copyright {
  position: absolute;
  bottom: 25px;
  right: 41px;
  z-index: 1;
  color: white;
  text-align: right;
}

.block-loader {
  background-color: var(--surface100);
  background-image: linear-gradient(
    89.42deg,
    rgba(35, 60, 75, 0) 0.25%,
    rgba(35, 60, 75, 0.5) 25.52%,
    rgba(35, 60, 75, 0) 49.5%
  );
  background-size: 200%;
  background-position: 100%;
  background-repeat: no-repeat;
  opacity: 0;
  animation: block-loader 1.8s infinite ease-out 0.8s, fade-in 0.4s forwards 1s;
  border-radius: 12px;
}

.section-container {
  background: var(--surface100);
  border-radius: 2px;
}

@keyframes block-loader {
  0% {
    background-position: 100%;
  }
  44% {
    background-position: 100%;
  }
  100% {
    background-position: -100%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1279px) {
  .section {
    margin: var(--spacing-15) 0;
  }

  .section-bg {
    padding: var(--spacing-15) 0;
  }

  .image-copyright {
    bottom: 16px;
    right: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .section-container {
    padding: var(--spacing-5);
  }
}

@media only screen and (max-width: 767px) {
  .section {
    margin: var(--spacing-10) 0;
  }

  .section-bg {
    padding: var(--spacing-10) 0;
  }

  .wrap {
    padding: 0 var(--spacing-3);
  }

  .wrap--xl {
    padding: 0;
  }

  .image-copyright {
    bottom: 8px;
    right: 16px;
  }

  .section-container {
    padding: var(--spacing-3);
  }
}
