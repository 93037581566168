.simple-slider__container {
  display: flex;
  flex-direction: column;
}

.simple-slider__title {
  text-align: center;
}

.simple-slider__slider {
  display: flex;
  overflow: visible;
  width: 100%;
}

.simple-slider__slider.locked .swiper-wrapper {
  justify-content: center;
}

.simple-slider__slider.locked .swiper-slide:last-of-type {
  margin-right: 0 !important;
}

.simple-slider .simple-slider__slide {
  flex: 0 0 256px;
  height: auto;
}

.simple-slider__slide:hover .simple-slider__slide-link {
  transform: scale(1.1);
}

.simple-slider__slide-link,
.simple-slider__slide-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: var(--transition);
}

.simple-slider__slide-img-container {
  position: relative;
  padding-top: 100%;
}

.simple-slider__slide-subtitle {
  opacity: 0.8;
}

.simple-slider__footer {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  gap: var(--spacing-5);
}

.simple-slider__nav {
  margin-left: auto;
}

.simple-slider__slide:nth-child(3n + 1) .simple-slider__slide-img {
  filter: url('#filter-blue');
}

.simple-slider__slide:nth-child(3n + 2) .simple-slider__slide-img {
  filter: url('#filter-grey');
}

.simple-slider__slide:nth-child(3n + 3) .simple-slider__slide-img {
  filter: url('#filter-red');
}

@media only screen and (max-width: 767px) {
  .simple-slider .simple-slider__slide {
    flex: 0 0 200px;
  }
}
