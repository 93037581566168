.faq__container {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-10);
}

.faq--left .faq__container {
  flex-direction: row-reverse;
}

.faq__header {
  flex: 0 0 calc((100% - var(--spacing-10)) * 0.4);
}

.faq__questions {
  flex: 0 0 calc((100% - var(--spacing-10)) * 0.6);
}

.faq__desc {
  margin-top: var(--spacing-2);
}

.faq__question {
  display: flex;
  flex-direction: column;
}

.faq__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-5);
  padding: var(--spacing-2);
  background-color: var(--surface400);
  color: white;
  text-align: left;
}

.faq__question:nth-of-type(2n) .faq__btn {
  background-color: var(--surface200);
  color: var(--text);
}

.faq__btn:focus-visible {
  outline: 2px dashed white;
  outline-offset: -6px;
}

.faq__question:nth-of-type(2n) .faq__btn:focus-visible {
  outline: 2px dashed var(--text);
}

.faq__btn svg {
  flex-shrink: 0;
  transition: var(--transition);
}

.faq__body {
  overflow: hidden;
}

.faq__body-content {
  padding: var(--spacing-2);
}

.faq-body {
  max-height: 0;
}

.faq-body-enter {
  opacity: 0;
  max-height: 0;
}
.faq-body-enter-active {
  opacity: 1;
  transition: var(--transition);
  max-height: var(--faq-item-height);
}
.faq-body-exit {
  opacity: 1;
  max-height: var(--faq-item-height);
}
.faq-body-exit-active {
  opacity: 0;
  transition: var(--transition);
  max-height: 0;
}

.faq a {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .faq--left .faq__header {
    text-align: right;
  }
}

@media only screen and (max-width: 767px) {
  .faq__container,
  .faq--left .faq__container {
    flex-direction: column;
    gap: var(--spacing-5);
  }

  .faq__questions {
    width: 100%;
  }
}
