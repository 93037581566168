.page-banner-small__container {
  display: flex;
}

.page-banner-small__content {
  width: 100%;
}

.page-banner-small__img-container {
  position: relative;
}

.page-banner-small__cta-container {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .page-banner-small {
    padding: var(--spacing-20) 0 var(--spacing-15);
  }

  .page-banner-small__container {
    align-items: center;
  }

  .page-banner-small__img-container {
    flex: 0 0 calc((100% - var(--spacing-5)) * 0.5);
    padding-top: calc((100% - var(--spacing-5)) * 0.5);
  }
}

@media only screen and (max-width: 767px) {
  .page-banner-small {
    padding: var(--spacing-15) 0 var(--spacing-10);
  }

  .page-banner-small__container {
    flex-direction: column;
  }

  .page-banner-small__img-container {
    width: 100%;
    padding-top: 100%;
  }
}
