.events__title {
  margin-bottom: var(--spacing-5);
}

.events__item-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-5);
}

.event-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 calc((100% - 2 * var(--spacing-5)) / 3);
}

.event-item__date {
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 17px;
  background-color: white;
  z-index: 1;
}

.event-item__date .body-small {
  margin-top: -0.5rem;
  font-weight: 500;
}

.event-item__img-container {
  position: relative;
  width: 100%;
  padding-top: 60.24%;
}

.event-item__desc {
  margin-top: 4px;
}

.event-item__info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event-item__info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.event-item__info svg {
  color: var(--primary);
  width: 2rem;
  height: 2rem;
}

@media only screen and (max-width: 1279px) {
  .event-item {
    flex: 0 0 calc((100% - var(--spacing-5)) / 2);
  }
}

@media only screen and (max-width: 767px) {
  .event-item {
    flex: 0 0 100%;
    gap: 8px;
  }
}
