.section-bg--white {
  padding: 0;
  margin: var(--spacing-20) 0;
}

.openai__container {
  display: flex;
}

.openai__left,
.openai__right {
  flex: 0 0 calc((100% - 80px) / 2);
}

.openai__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.openai__btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

@media only screen and (max-width: 1279px) {
  .section-bg--white {
    margin: var(--spacing-15) 0;
  }

  .openai__container {
    flex-direction: column;
  }

  .openai__left,
  .openai__right {
    flex: 0 0 auto;
  }

  .openai__title br {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .section-bg--white {
    margin: var(--spacing-10) 0;
  }
}
