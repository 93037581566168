.feedback {
  text-align: center;
}

.feedback__cta {
  justify-content: center;
  width: 100%;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .feedback {
    padding: var(--spacing-10) var(--spacing-5) var(--spacing-5);
  }

  .feedback__cta {
    margin-top: var(--spacing-5);
  }
}

@media only screen and (max-width: 767px) {
  .feedback {
    padding: var(--spacing-5) var(--spacing-3) var(--spacing-3);
  }

  .feedback__cta {
    margin-top: var(--spacing-3);
  }
}
