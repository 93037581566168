.page-banner:not(.page-banner--no-image) {
  background-image: linear-gradient(to top, transparent 80px, var(--surface100) 80px);
}

.page-banner--no-image {
  background-color: var(--surface100);
}

.page-banner__content {
  padding: var(--spacing-20) 0 var(--spacing-10);
}

.page-banner__desc {
  max-width: 1024px;
}

.page-banner__img-container {
  position: relative;
  padding-top: 56.25%;
}

@media only screen and (max-width: 1279px) {
  .page-banner__content {
    padding: var(--spacing-15) 0 var(--spacing-10);
  }
}

@media only screen and (max-width: 767px) {
  .page-banner__content {
    padding: var(--spacing-10) 0 var(--spacing-5);
  }
}
