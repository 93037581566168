.support__contact {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}

.support__question {
  display: flex;
  flex-direction: column;
}

.support__question:not(:last-of-type) {
  margin-bottom: 16px;
}

.support__btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-5);
  padding: 15px 0;
  text-align: left;
  border-bottom: 1px solid var(--text);
}

.support__btn svg {
  flex-shrink: 0;
  transition: var(--transition);
  width: 2.4rem;
  height: 2.4rem;
}

.support__body {
  overflow: hidden;
}

.support__body-content {
  padding-top: 8px;
}

.support-body {
  max-height: 0;
}

.support-body-enter {
  opacity: 0;
  max-height: 0;
}
.support-body-enter-active {
  opacity: 1;
  transition: var(--transition);
  max-height: var(--support-item-height);
}
.support-body-exit {
  opacity: 1;
  max-height: var(--support-item-height);
}
.support-body-exit-active {
  opacity: 0;
  transition: var(--transition);
  max-height: 0;
}

.support a:not(.btn) {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .support__title {
    margin-bottom: 40px;
  }

  .support__contact {
    margin-bottom: 40px;
  }

  .support__section {
    margin-top: 80px;
  }

  .support__section-title {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .support__title,
  .support__contact {
    margin-bottom: 20px;
  }

  .support__section {
    margin-top: 40px;
  }

  .support__section-title {
    margin-bottom: 16px;
  }
}
