.ai-carousel__header {
  display: flex;
  align-items: flex-end;
}

.ai-carousel__nav {
  margin-left: auto;
}

.ai-carousel__slider.swiper {
  display: flex;
  overflow: visible;
  width: 100%;
}

.ai-carousel__slider.locked .swiper-wrapper {
  justify-content: center;
}

.ai-carousel__slider.locked .swiper-slide:last-of-type {
  margin-right: 0 !important;
}

@media only screen and (min-width: 768px) {
  .ai-carousel__header {
    margin-bottom: var(--spacing-10);
  }

  .ai-carousel__slider .ai-carousel__slide {
    width: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .ai-carousel__header {
    flex-wrap: wrap;
    margin-bottom: var(--spacing-5);
  }

  .ai-carousel__slider .ai-carousel__slide {
    width: 60%;
  }
}
