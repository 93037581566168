.footer {
  padding: 80px 0;
  margin-top: auto;
  font-weight: 600;
  background-color: var(--surface300);
  color: white;
}

.footer__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__right {
  flex: 0 0 368px;
}

.footer .hs-form-field label {
  font-size: 1.8rem;
  margin: 0;
}

.footer__logo {
  width: 267px;
  height: auto;
}

.footer__right-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 40px;
}

.footer__corp-icon,
.footer__iso {
  height: 100px;
  width: auto;
}

.footer__info {
  text-decoration: underline;
}

.footer .newsletter-inline {
  margin-top: auto;
}

.footer__bottom,
.footer__info {
  margin-left: 42px;
}

.footer__bottom-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__social,
.footer__bottom-list {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
}

.footer__bottom-link {
  display: block;
  text-decoration: underline;
}

.footer__bottom-text,
.footer__bottom-link {
  padding: 13px 0;
}

.footer__social-link,
.footer__address-link {
  display: block;
}

.footer .footer__container *:focus-visible {
  outline: 1px solid white;
}

.footer__bottom-item {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1279px) {
  .footer__top,
  .footer__bottom-nav {
    flex-direction: column;
  }

  .footer__right {
    flex: 0 0 auto;
  }

  .footer__right-header {
    justify-content: flex-start;
  }

  .footer__corp {
    margin: 0;
  }

  .footer__right {
    margin: 0 42px;
  }

  .footer__bottom-nav {
    align-items: flex-start;
  }

  .footer__bottom-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .footer__bottom-list {
    flex-direction: column;
  }

  .footer__info,
  .footer__right {
    margin: 0 42px;
  }

  .footer__right-header {
    gap: 20px;
  }

  .footer__bottom {
    margin: 0 36px;
  }
}
