.case__header-info,
.case__header-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.case__header-info {
  justify-content: space-between;
}

.case__body {
  margin: var(--spacing-20) auto;
  font-weight: 500;
}

.case__body.markdown h2,
.case__body.markdown h3,
.case__body.markdown h4,
.case__body.markdown h6,
.case__body.markdown h5,
.case__body.markdown ol,
.case__body.markdown ul,
.case__body.markdown a,
.case__body.markdown p {
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}

.case__body h2:not(:first-child) {
  margin-top: 80px;
}

.case__body h3:not(:first-child) {
  margin-top: 60px;
}

.case__body img,
.case__body p:has(img),
.case__body.markdown .video {
  display: block;
  margin: 80px auto;
  max-width: min(880px, 100%);
}

.case__body .vjs-poster img {
  margin: 0;
  max-width: none;
}

.case__img {
  width: 100%;
  display: block;
}

.bctt-ctt-btn {
  display: none;
}

.case__body blockquote {
  display: block;
  padding: var(--spacing-10);
  color: white;
  font-size: 4rem;
  text-decoration: none;
  background-color: var(--primary);
}

.case .case__body blockquote p {
  max-width: none;
}

.case .case__body blockquote a {
  color: white;
}

@media only screen and (max-width: 1279px) {
  .case__body {
    margin: var(--spacing-15) auto;
  }
}

@media only screen and (max-width: 899px) {
  .page-title__info {
    margin-top: 24px;
  }

  .case__header-info {
    flex-direction: column;
  }

  .case__body img,
  .case__body p:has(img),
  .case__body.markdown .video {
    margin: 40px auto;
  }

  .case__content {
    margin-bottom: 80px;
  }

  .case__body blockquote {
    padding: var(--spacing-5);
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 767px) {
  .case__body {
    margin: var(--spacing-10) auto;
  }
}
