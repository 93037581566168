.people-showcase__content {
  align-items: center;
}

.people-showcase__container,
.people-showcase__first-container,
.people-showcase__last-container {
  display: flex;
  flex-wrap: wrap;
}

.people-showcase__container {
  width: 100%;
}

.people-showcase__last-container {
  flex: 0 0 100%;
  width: 0;
}

.people-showcase__first-container {
  flex: 0 0 calc((100% - var(--spacing-5)) * 0.6);
  width: 0;
}

.people-showcase__first-container .people-showcase__item {
  flex: 0 0 calc((100% - var(--spacing-5) * 2) / 3);
  width: 0;
}

.people-showcase__last-container .people-showcase__item {
  flex: 0 0 calc((100% - var(--spacing-5) * 4) / 5);
  width: 0;
}

.people-showcase__main {
  flex: 0 0 calc((100% - var(--spacing-5)) * 0.4);
  width: 0;
  background-color: var(--primary);
  color: white;
}

.people-showcase__img-container {
  position: relative;
  padding-top: 100%;
}

.people-showcase__expertise {
  opacity: 0.8;
}

.people-showcase__main img {
  filter: url('#filter-grey');
}

.people-showcase__first-container .people-showcase__item:nth-child(4n + 1) img {
  filter: url('#filter-blue');
}

.people-showcase__first-container .people-showcase__item:nth-child(4n + 2) img {
  filter: url('#filter-grey');
}

.people-showcase__first-container .people-showcase__item:nth-child(4n + 3) img {
  filter: url('#filter-red');
}

.people-showcase__first-container .people-showcase__item:nth-child(4n + 4) img {
  filter: url('#filter-red');
}

.people-showcase__last-container .people-showcase__item:nth-child(3n + 1) img {
  filter: url('#filter-grey');
}

.people-showcase__last-container .people-showcase__item:nth-child(3n + 2) img {
  filter: url('#filter-red');
}

.people-showcase__last-container .people-showcase__item:nth-child(3n + 3) img {
  filter: url('#filter-blue');
}

a.people-showcase__main:hover .people-showcase__item-title,
a.people-showcase__item:hover .people-showcase__item-title {
  text-decoration: underline;
}

@media only screen and (max-width: 1279px) {
  .people-showcase__main {
    flex: 0 0 100%;
  }

  .people-showcase__first-container {
    flex: 0 0 100%;
  }

  .people-showcase__first-container .people-showcase__item {
    flex: 0 0 calc((100% - var(--spacing-5) * 2) / 3);
  }

  .people-showcase__last-container .people-showcase__item {
    flex: 0 0 calc((100% - var(--spacing-5) * 1) / 2);
  }

  .people-showcase__main .people-showcase__img-container {
    max-width: 550px;
    padding-top: min(100%, 550px);
  }
}

@media only screen and (max-width: 767px) {
  .people-showcase__first-container .people-showcase__item {
    flex: 0 0 calc((100% - var(--spacing-3)) / 2);
  }

  .people-showcase__first-container .people-showcase__item:nth-child(3n + 1) img {
    filter: url('#filter-blue');
  }

  .people-showcase__first-container .people-showcase__item:nth-child(3n + 2) img {
    filter: url('#filter-grey');
  }

  .people-showcase__first-container .people-showcase__item:nth-child(3n + 3) img {
    filter: url('#filter-red');
  }
}
