.ai-carousel__slide-img-container {
  position: relative;
  padding-top: 60%;
  background-color: var(--text);
}

.ai-carousel__slide-content {
  flex-grow: 1;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .ai-carousel__chat-container {
    padding: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .ai-carousel__chat-container {
    padding: 40px 20px;
  }
}
