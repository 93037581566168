#modal {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(35, 59, 75, 0.3);
  z-index: 110;
}

.modal:not(.right) {
  overflow-y: auto;
}

.modal__overflow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 0;
  width: 100%;
  min-height: 100%;
}

.modal__overflow > div {
  max-width: 937px;
  width: calc(100% - 32px);
}

.small .modal__overflow > div {
  max-width: 640px;
}

.modal.bottom .modal__overflow {
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.modal__container {
  position: relative;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}

.modal.bottom .modal__container,
.modal.right .modal__container {
  max-height: 100%;
  overflow-y: auto;
}

.right .modal__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 800px;
}

.bottom .modal__container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px;
  line-height: 1;
  color: var(--primary);
}

.modal__close svg {
  transform: rotate(45deg);
}

.modal-enter {
  opacity: 0;
}
.modal-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.modal-enter.right .modal__container {
  transform: translateX(100%);
}
.modal-enter-active.right .modal__container {
  transform: translateX(0);
  transition: 300ms;
}
.modal-exit.right .modal__container {
  transform: translateX(0);
}
.modal-exit-active.right .modal__container {
  transform: translateX(100%);
  transition: 300ms;
}

.modal-enter.bottom .modal__container {
  transform: translateY(100%);
}
.modal-enter-active.bottom .modal__container {
  transform: translateY(0);
  transition: 300ms;
}
.modal-exit.bottom .modal__container {
  transform: translateY(0);
}
.modal-exit-active.bottom .modal__container {
  transform: translateY(100%);
  transition: 300ms;
}

.modal__ai-container {
  padding: 40px;
}

@media only screen and (max-width: 899px) {
  .right .modal__container {
    width: calc(var(--vw) * 100);
  }

  .modal__close {
    top: 4px;
    right: 4px;
  }
}

.keep-mounted-closed {
  display: none;
}
