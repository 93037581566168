.h1,
.h2,
.h3,
.h4 {
  letter-spacing: -0.05em;
  font-weight: 600;
}

.h1,
.h2,
.markdown h1 {
  line-height: 1.15;
}

.h3,
.h4,
.markdown h2,
.markdown h3,
.markdown h4 {
  line-height: 1.25;
}

.h1,
.markdown h1 {
  font-size: 8rem;
}

.h2 {
  font-size: 7.2rem;
}

.h3,
.markdown h2 {
  font-size: 4rem;
}

.h4,
.markdown h3,
.markdown h4 {
  font-size: 2.4rem;
}

.landing .h1,
.landing .h2,
.landing .h3,
.landing .h4 {
  letter-spacing: -0.05em;
  line-height: 1.15;
  font-weight: 600;
}

.landing .h1 {
  font-size: 8.2rem;
}

.landing .h2 {
  font-size: 7rem;
}

.landing .h3 {
  font-size: 3.2rem;
}

.landing .h4 {
  font-size: 1.9rem;
}

.body-large,
.body-regular,
.body-small {
  line-height: 1.5;
  letter-spacing: -0.05em;
}

.body-large {
  font-size: 2.4rem;
}

.body-regular {
  font-size: 2rem;
}

.body-small {
  font-size: 1.8rem;
}

.body-label {
  font-size: 1.8rem;
}

.controls-link,
.controls-link-small {
  font-weight: 600;
}

.controls-link-small {
  font-size: 1.8rem;
}

.controls-link {
  font-size: 2rem;
}

.tag {
  display: inline-block;
  padding: 8px;
  background-color: var(--surface200);
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: -0.05em;
}

.tag--light {
  background-color: white;
}

.tag--dark {
  background-color: var(--surface400);
  color: white;
}

.tag--filter {
  font-size: 2rem;
  font-weight: 600;
  padding: 13px 24px;
  background-color: var(--surface200);
}

.tag--active {
  background-color: var(--primary);
  color: white;
}

.markdown h2 {
  margin-bottom: 40px;
}

.markdown h3 {
  margin-bottom: 20px;
}

.markdown ol:not(:last-child),
.markdown ul:not(:last-child),
.markdown blockquote:not(:last-child),
.markdown p:not(:last-child) {
  margin: 0 0 30px;
}

.markdown ol li:not(:last-child),
.markdown ul li:not(:last-child) {
  margin: 0 0 20px;
}

.markdown a {
  color: var(--primary);
  text-decoration: underline;
}

.markdown ol,
.markdown ul {
  padding: 0;
}

.markdown ol li {
  list-style: decimal;
  padding-left: 1rem;
  list-style-position: inside;
}

.markdown ul li {
  list-style: disc;
  list-style-position: inside;
}

.markdown li ol,
.markdown li ul {
  margin: 20px 0 0 20px;
}

.markdown ul li p,
.markdown ol li p {
  display: inline;
}

.markdown .video-container {
  margin: 40px auto;
}

@media only screen and (max-width: 1279px) {
  .h1,
  .markdown h1 {
    font-size: 6.4rem;
  }

  .h2 {
    font-size: 4.8rem;
  }

  .h3,
  .markdown h2 {
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 767px) {
  .h1,
  .markdown h1 {
    font-size: 4.8rem;
  }

  .h2 {
    font-size: 3.6rem;
  }

  .h3,
  .markdown h2 {
    font-size: 2.4rem;
  }

  .h4,
  .markdown h3 {
    font-size: 2rem;
  }

  .landing .h1 {
    font-size: 5.2rem;
  }

  .landing .h2 {
    font-size: 4rem;
  }

  .landing .h3 {
    font-size: 2.4rem;
  }

  .body-regular {
    font-size: 1.8rem;
  }

  .body-large {
    font-size: 2rem;
  }

  .body-small {
    font-size: 1.6rem;
  }

  .controls-link {
    font-size: 1.8rem;
  }
}
