.contact {
  position: relative;
}

.contact__container {
  display: flex;
  align-items: center;
  background-color: var(--surface100);
}

.contact.section-bg--grey .contact__container {
  background-color: white;
}

.contact__content {
  align-items: flex-start;
  flex: 0 0 50%;
}

.content__img-container {
  position: relative;
  flex: 0 0 calc(50% - 80px);
  align-self: stretch;
}

.contact__media-container {
  position: relative;
  flex: 0 0 50%;
  margin-left: -80px;
}

.contact__media {
  display: block;
  width: 100%;
}

.contact__desc a {
  text-decoration: underline;
}

.contact.contact--no-img .contact__container {
  justify-content: center;
  background-color: transparent;
  text-align: center;
  padding: 0;
}

.contact.contact--no-img.section-bg--primary .contact__container {
  color: white;
}

.contact.contact--no-img .contact__content {
  align-items: center;
  flex: 0 1 745px;
}

@media only screen and (max-width: 1279px) {
  .contact__media-container {
    flex: 0 0 calc(50% + 40px);
  }
}

@media only screen and (max-width: 767px) {
  .contact__container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .contact__media-container {
    margin-left: -24px;
  }

  .content__img-container {
    padding-top: 80%;
  }

  .contact.contact--no-img .contact__content {
    flex-basis: auto;
  }
}
